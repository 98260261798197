(function ($) {
    "use strict";
   var Brw_Frontend = {
    init: function () {
        this.brw_datepicker();
        this.submit_button();
        this.ova_collapsed();
        this.ova_get_time_to_book_start();
        this.ova_get_time_to_book_end();
        this.ova_ajax_show_total();
        this.ova_ajax_show_total_after_load();
        this.ova_search_ajax();
        this.ova_period_time();
    },

    brw_datepicker: function() {

        // get firstday
        var firstday = $('.ovabrw_datetimepicker').data('firstday');

        /* Calendar language */
        if (typeof brw_lang_general_calendar !== 'undefined') {
            $.datetimepicker.setLocale( brw_lang_general_calendar );
        }
        var disweek_arr = '';
        if( typeof brw_disable_week_day !== 'undefined' ){
            disweek_arr = brw_disable_week_day.split(',').map(function(item) {
                return parseInt(item, 10);
            });
        }

        var allowTimes = '';
        if(typeof brw_time_to_book_general_calendar !== 'undefined' ){
            allowTimes =  brw_time_to_book_general_calendar.replace(/ /g,'').split( ',' );
        }

     
        var data_step = 30;
        if(typeof brw_data_step !== 'undefined' ){
            data_step = brw_data_step;
        }
        

        var time_format = '';
        if(typeof brw_format_time !== 'undefined' ){
            time_format = brw_format_time;
        }
        
        var date_format = '';
        if(typeof brw_date_format !== 'undefined' ){
            date_format = brw_date_format;
        }    
        switch( date_format ){
            case 'd-m-Y':
                date_format = 'DD-MM-Y';
                break;
            case 'm/d/Y':
                date_format = 'MM/DD/Y';
                break;
            case 'Y/m/d':
                date_format = 'Y/MM/DD';
                break;
            case 'Y-m-d':
                date_format = 'Y-MM-DD';
                break;
            default:
                date_format = 'DD-MM-Y';
        }
        
        
        var today = new Date();

        // Setup Mask date for datetimepicker via moment library
        $.datetimepicker.setDateFormatter({
            
            parseDate: function (date, format) {
                var d = moment(date, format);
                return d.isValid() ? d.toDate() : false;
            },
            
            formatDate: function (date, format) {
                return moment(date).format(format);
            },

           
        });

        // Check mobile
        if ($(window).width() <= 1024){
            $('.ovabrw_datetimepicker').datetimepicker({closeOnTimeSelect: false}); // Not closed when selecting time.
        }

        $('.ovabrw_datetimepicker').each(function(){

            /* Disable Date */
            var disabledDates = [];
            var order_time_arr_new = [];
            var order_time = $(this).data('order-time');

            if( order_time ){
                order_time_arr_new = order_time;
            }
           
            order_time_arr_new.forEach(function(item, index){
                if( item.hasOwnProperty('rendering') ) {

                    if ( item.start_v2 ) {
                        disabledDates.push(item.start_v2);
                    }
                    
                }
            });


            // Default Hour Start
            var defaultTime = $(this).data('default_hour');

            var datePickerOptions = {
                scrollInput: false,
                dayOfWeekStart: firstday,
                minDate: today,
                disabledWeekDays: disweek_arr,
                format: date_format+' '+time_format,
                formatDate: date_format,
                formatTime:time_format,
                step: data_step, 
                allowTimes: allowTimes,
                timepicker:true,
                autoclose: true,
                disabledDates: disabledDates,
                defaultTime: defaultTime
            }

            if ( $(this).hasClass('no_time_picker') ) {
                datePickerOptions = {
                    scrollInput: false,
                    dayOfWeekStart: firstday,
                    minDate: today,
                    disabledWeekDays: disweek_arr,
                    format: date_format,
                    formatDate: date_format,
                    formatTime:time_format,
                    step: data_step, 
                    allowTimes: allowTimes,
                    timepicker:true,
                    autoclose: true,
                    disabledDates: disabledDates,
                    defaultTime: defaultTime
                };
            }

            $(this).datetimepicker(datePickerOptions);
        });

        
        $('.ovabrw_datetimepicker.ovabrw_start_date').on('click', function(){
            /* Disable Date */
            var disabledDates = [];
            var order_time_arr_new = [];
            var order_time = $(this).attr( 'order-time' );
            var product_disable_week_day = $(this).data('disable-week-day');
            if( product_disable_week_day ){
                disweek_arr = product_disable_week_day.toString().split(',').map(function(item) {
                    return parseInt(item, 10);
                });
            }

            if( order_time ){
                order_time_arr_new = JSON.parse( order_time );
            }
           
            order_time_arr_new.forEach(function(item, index){
                if( item.hasOwnProperty('rendering') ) {

                    if ( item.start_v2 ) {
                        disabledDates.push(item.start_v2);
                    }
                    
                }
            });
            
            // Default Hour Start
            var defaultTime = $(this).data('default_hour');

            var time_format = '';
            if( typeof brw_format_time !== 'undefined' ){
                time_format = brw_format_time;    
            }

            var timepicker = $(this).attr('timepicker');
            if( timepicker == 'true' ){
                timepicker = true;
            }else{
                timepicker = false;
            }

            var time_to_book = $(this).data('time_to_book');
            var allowTimes = Brw_Frontend.ova_get_time_to_book_start(time_to_book);

            var datePickerOptions = {
                dayOfWeekStart: firstday,
                minDate: today,
                disabledWeekDays: disweek_arr,
                autoclose: true,
                step: data_step,
                format: date_format+' '+time_format,    
                formatDate: date_format,
                formatTime: time_format,
                defaultTime: defaultTime,
                allowTimes: allowTimes,
                timepicker: timepicker,
                disabledDates: disabledDates,
            };

            if( $(this).hasClass('no_time_picker') || (allowTimes.length == 0) || timepicker == false ) {

                datePickerOptions = {
                    dayOfWeekStart: firstday,
                    minDate: today,
                    disabledWeekDays: disweek_arr,
                    autoclose: true,
                    step: data_step,
                    format: date_format,    
                    formatDate: date_format,
                    defaultTime: false,
                    allowTimes: allowTimes,
                    timepicker: false,
                    disabledDates: disabledDates,
                };
            }

            $(this).datetimepicker(datePickerOptions);
        });


        $('.ovabrw_datetimepicker.ovabrw_end_date').on('click',function(){
            /* Disable Date */
            var disabledDates = [];
            var order_time_arr_new = [];
            var order_time = $(this).attr( 'order-time' );
            var product_disable_week_day = $(this).data('disable-week-day');
            if( product_disable_week_day ){
                disweek_arr = product_disable_week_day.toString().split(',').map(function(item) {
                    return parseInt(item, 10);
                });
            }

            if( order_time ){
                order_time_arr_new = JSON.parse( order_time );
            }
           
            order_time_arr_new.forEach(function(item, index){
                if( item.hasOwnProperty('rendering') ) {

                    if ( item.start_v2 ) {
                        disabledDates.push(item.start_v2);
                    }
                    
                }
            });

            var timepicker = $(this).attr('timepicker');
            if( timepicker == 'true' ){
                timepicker = true;
            }else{
                timepicker = false;
            }

            var time_format = '';
            if( typeof brw_format_time !== 'undefined' ){
                time_format = brw_format_time;    
            }
            

            var time_to_book = $(this).data('time_to_book');
            var allowTimes = Brw_Frontend.ova_get_time_to_book_end(time_to_book);
            
            var defaultTime = $(this).data('default_hour');

            if( $(this).hasClass('no_time_picker') || (allowTimes.length == 0) || timepicker == false ) {
                timepicker = false;
                time_format = '';
                defaultTime = false;
            } 

            /* Get Min Time */
            var min = $(this).closest('form').find('.ovabrw_start_date').val();
            if( ! min ) {
                min = $('.ovabrw_start_date.ovabrw_datetimepicker').val();
            }

            var datePickerOptions = {

                dayOfWeekStart: firstday,
                disabledWeekDays: disweek_arr,
                autoclose: true,
                step: data_step,
                minDate: min ? min : today,
                format: date_format+' '+time_format,
                formatDate: date_format,
                formatTime:time_format,
                defaultTime: defaultTime,
                allowTimes: allowTimes,
                timepicker:timepicker,
                disabledDates: disabledDates,
            }

            if( $(this).hasClass('no_time_picker') || (allowTimes.length == 0) || timepicker == false ) {

                datePickerOptions = {
                    dayOfWeekStart: firstday,
                    minDate: min ? min : today,
                    disabledWeekDays: disweek_arr,
                    autoclose: true,
                    step: data_step,
                    format: date_format,    
                    formatDate: date_format,
                    defaultTime: false,
                    allowTimes: allowTimes,
                    timepicker: false,
                    disabledDates: disabledDates,
                };
            }

            $(this).datetimepicker(datePickerOptions);

        });

        $('.ovabrw_datetimepicker').focus(function(e) {
            $(this).blur();
        });
    },

    submit_button: function() {
        $('.ovabrw_btn_submit').on('click', function(e){
            var content_required = $(this).closest('.ovabrw_search').data('mesg_required');
            var flag = true;

            $(this).closest('.ovabrw_search').find('.content .wrap-error').empty();
            var class_require = $(this).closest('.form_ovabrw').find('.required');
            class_require.each(function(){
                if ( ! $(this).val() ) {
                    flag = false;
                    $(this).parent('.content').children('.wrap-error').append('<p class="error">'+content_required+'</p>');
                }
            });
            if ( ! flag ) {
                e.preventDefault();
                return false;
            }
        });

        /* Booking Form submit */
        $('form.booking_form button.booking-form-submit').on('click', function(e){
            $(this).closest('.ova-booking-form').find('.required').each(function(){
                if ( !$(this).val() ) {
                    var error = $(this).data('error');
                    $(this).closest('.ova-booking-form').find('.ajax-error').html('').append(error).show();
                    e.preventDefault();
                    return false;
                } else {
                    $(this).closest('.ova-booking-form').find('.ajax-error').html('').hide();
                }
            });
        });

        /* Request Form submit */
        $('form.request-form button.request-form-submit').on('click', function(e){
            $(this).closest('.ova-request-form').find('.required').each(function(){
                if ( !$(this).val() ) {
                    var error = $(this).data('error');
                    $(this).closest('.ova-request-form').find('.ajax-error').html('').append(error).show();
                    e.preventDefault();
                    return false;
                } else {
                    $(this).closest('.ova-booking-form').find('.ajax-error').html('').hide();
                }
            });
        });
    },

    ova_collapsed: function() {
        $('.ovabrw-according').off().on('click', function(e){
            e.preventDefault();
            $(this).siblings('.ovabrw_collapse_content').slideToggle();
        });

        //open popup
        $('.ovabrw_open_popup').off().on('click', function(e){
            e.preventDefault();
            $(this).siblings('.popup').css('display', 'block');
        });

        //close popup
        $('.popup-close').on('click', function(e){
            e.preventDefault();
            $(this).parent('.popup-inner').parent('.popup').css('display', 'none');
        });

        $('.popup-close-2').on('click', function(e){
            e.preventDefault();
            $(this).parent('.close_discount').parent('.popup-inner').parent('.popup').css('display', 'none');
        });
    },

    ova_get_time_to_book_start: function(time_to_book) {
        
        var time_to_book_arr = [];
        var time_to_book_general = [];
        if(typeof brw_time_to_book_general_calendar !== 'undefined' ){
            time_to_book_general =  brw_time_to_book_general_calendar.replace(/ /g,'').split( ',' );
        }

        if( typeof time_to_book == 'undefined' ) {
            time_to_book_arr = time_to_book_general;
        } else if( time_to_book == 'no' ) {
            time_to_book_arr = [];
        } else {
            time_to_book_arr =  time_to_book.replace(/ /g,'').split( ',' );
        }

        return time_to_book_arr;

    },

    ova_get_time_to_book_end: function(time_to_book) {

        var time_to_book_arr = []; 
        var time_to_book_endate_general = [];
        if(typeof brw_time_to_book_general_calendar_endate !== 'undefined' ){
            time_to_book_endate_general =  brw_time_to_book_general_calendar_endate.replace(/ /g,'').split( ',' );
        }

        
        if( typeof time_to_book == 'undefined' ) {
            time_to_book_arr = time_to_book_endate_general;
        } else if( time_to_book == 'no' ) {
            time_to_book_arr = [];
        } else {
            time_to_book_arr =  time_to_book.replace(/ /g,'').split( ',' );
        }

        return time_to_book_arr;

    },

    ova_ajax_show_total: function(){


        $( 'body' ).on( 'change', '.booking_form', function(){

            Brw_Frontend.ova_ajax_show_total_after_load($(this));

        });

        $('.booking_form').each( function() {
            var that = $(this);
            
            var check_in    = that.find('input[name="ovabrw_pickup_date"]').val();
            var check_out   = that.find('input[name="ovabrw_pickoff_date"]').val();
            var package_id  = that.find('select[name="ovabrw_period_package_id"]').val();
        
            if ( ( check_in && check_out ) || ( check_in && package_id ) ) {
                Brw_Frontend.ova_ajax_show_total_after_load(that);
            }
            
        });

    },

    ova_ajax_show_total_after_load: function( that = null ) {

        var pickup_loc, dropoff_loc, pickup_date, dropoff_date, quantity, resources, services, id, package_id, deposit;
        var resources = [];
        var services = [];
        var obj_resource, obj_service;

        if ( that != null ) {   
            if( that.find('input[name="product_id"]').val() ){
                id = that.find('input[name="product_id"]').val();
            }

            if( that.find('select[name="ovabrw_pickup_loc"]').val() ){
                pickup_loc = that.find('select[name="ovabrw_pickup_loc"]').val();
            }

            if( that.find('select[name="ovabrw_pickoff_loc"]').val() ){
                dropoff_loc = that.find('select[name="ovabrw_pickoff_loc"]').val();
            }

            if( that.find('input[name="ovabrw_pickup_date"]').val() ){
                pickup_date = that.find('input[name="ovabrw_pickup_date"]').val();
            }

            if( that.find('input[name="ovabrw_pickoff_date"]').val() ){
                dropoff_date = that.find('input[name="ovabrw_pickoff_date"]').val();
            }

            if( that.find('select[name="ovabrw_period_package_id"]').val() ){
                package_id = that.find('select[name="ovabrw_period_package_id"]').val();
            }
            
            if( that.find('input[name="ovabrw_number_vehicle"]').val() ){
                quantity = that.find('input[name="ovabrw_number_vehicle"]').val();
            }

            if( that.find('input[name="ova_type_deposit"]:checked').val() ){
                deposit = that.find('input[name="ova_type_deposit"]:checked').val();
            }

            that.find(".ovabrw-resources input[type=checkbox]:checked").each(function () {
                resources[$(this).data('rs-key')] = $(this).val();
            });
            obj_resource = $.extend({}, resources);

            // Service
            that.find( "select[name='ovabrw_service[]']" ).each( function() {
                if( $(this).val() ){
                    services.push($(this).val());    
                }
            });
            obj_service = $.extend({}, services);

            if ( ( pickup_date && dropoff_date ) || ( pickup_date && package_id ) ) {
                var show_amount     = that.find('.ajax-show-total .ovabrw-show-amount').css('display', 'flex');
                var ajax_loading    = that.find('.ajax-show-total .ajax-loading-total').show();
                that.find('.ajax-show-total .show-availables-number').html('');
                that.find('.ajax-show-total .show-amount-insurance').html('');
                that.find('.ajax-show-total .show-total-number').html('');

                $.ajax({
                    url: ajax_object.ajax_url,
                    type: 'POST',
                    data: ({
                        action: 'ovabrw_calculate_total',
                        pickup_loc: pickup_loc,
                        dropoff_loc: dropoff_loc,
                        pickup_date: pickup_date,
                        package_id: package_id,
                        dropoff_date: dropoff_date,
                        quantity: quantity,
                        deposit: deposit,
                        resources: JSON.stringify( obj_resource ),
                        services: JSON.stringify( obj_service ),
                        id: id,
                    }),
                    success: function(response){
                        var data = JSON.parse(response);

                        if ( response != 0 && data ) {
                            that.find('.ajax-show-total .show-availables-number').html('').append(data['number_vehicle_available']);
                            that.find('.ajax-show-total .show-amount-insurance').html('').append(data['amount_insurance']);
                            that.find('.ajax-show-total .show-total-number').html('').append(data['line_total']);
                            that.find('.ajax-show-total .ovabrw-ajax-amount-insurance').show();
                        }
                        
                        ajax_loading.hide();
                    },
                });
            }
        }

    },

    ova_search_ajax: function() {

        if ( $('.ovabrw-search-ajax .wrap-search-ajax').length > 0 ) {
            loadAjaxSearch();
        }

        $('.ovabrw-search-ajax .wrap-search-ajax .ovabrw-btn').on('click', function(e){

            loadAjaxSearch( true );

            $('html, body').animate({
                scrollTop: $("#brw-search-ajax-result").offset().top - 250
            }, 500);
            
            // hide filter sort by dropdown 
            $(this).closest('.wrap-search-ajax').find('.input_select_list').hide();

            e.preventDefault();
        });


        /* Result Layout */
        $('.ovabrw-search-ajax').on('click', '.wrap-search-ajax .filter-layout' , function(e) {
            e.preventDefault();

            var that          = $(this);
            var layout_active = $('.wrap-search-ajax .filter-layout-active').attr('data-layout');
            var layout        = that.attr('data-layout');
            var clicked       = that.closest('.wrap-search-ajax').find('.ovabrw-products-result').data('clicked');

            if ( layout != layout_active ) {
                $('.wrap-search-ajax .filter-layout').removeClass('filter-layout-active');
                that.addClass('filter-layout-active');

                if ( clicked ) {
                    loadAjaxSearch( true );
                } else {
                    loadAjaxSearch();
                }
            }
        });

        /* Sort by */
        $('.ovabrw-search-ajax').on('click', '.wrap-search-ajax .ovabrw-tour-filter .input_select_list .term_item' , function(e) {
            e.preventDefault();

            var that          = $(this);
            var sort_by_value = that.closest('.filter-sort').find('.input_select_input_value').val();
            var search_result = that.closest('.wrap-search-ajax').find('.brw-search-ajax-result');
            var clicked       = that.closest('.wrap-search-ajax').find('.ovabrw-products-result').data('clicked');

            if( sort_by_value == 'id_desc') {
                search_result.data('order','DESC');
                search_result.data('orderby','ID');
                search_result.data('orderby_meta_key','');
            } else if( sort_by_value == 'rating_desc' ) {
                search_result.data('order','DESC');
                search_result.data('orderby','meta_value_num');
                search_result.data('orderby_meta_key','_wc_average_rating');
            } else if( sort_by_value == 'price_asc' ) {
                search_result.data('order','ASC');
                search_result.data('orderby','meta_value_num');
                search_result.data('orderby_meta_key','_price');
            } else if( sort_by_value == 'price_desc' ) {
                search_result.data('order','DESC');
                search_result.data('orderby','meta_value_num');
                search_result.data('orderby_meta_key','_price');
            }

            if ( clicked ) {
                loadAjaxSearch( true );
            } else {
                loadAjaxSearch();
            }
        });

        /* Pagination */
        $(document).on('click', '.ovabrw-search-ajax .wrap-search-ajax .ovabrw-pagination-ajax .page-numbers', function(e) {
            e.preventDefault();

            var that    = $(this);
            var current = $('.wrap-search-ajax .ovabrw-pagination-ajax .current').attr('data-paged');
            var paged   = that.attr('data-paged');
            var clicked = that.closest('.brw-search-ajax-result').find('.ovabrw-products-result').data('clicked');

            if ( current != paged ) {
                $(window).scrollTop(0);
                $('.wrap-search-ajax .ovabrw-pagination-ajax .page-numbers').removeClass('current');
                that.addClass('current');

                if ( clicked ) {
                    loadAjaxSearch( true );
                } else {
                    loadAjaxSearch();
                }
            }
        });

        // Event click clear filter
        $(".ovabrw-tour-filter .clear-filter").on( "click", function(e) {
            e.preventDefault();
            var clear_btn       = $(this);
            var wrap_search     = clear_btn.closest('.wrap-search-ajax');
            var guests          = wrap_search.data('guests');
            var sort_by_default = wrap_search.data('sort_by_default');

            //reset data-paged
            clear_btn.closest('.wrap-search-ajax').find('.ovabrw-pagination-ajax').attr('data-paged', 1);

            // reset all input search bar
            wrap_search.find('.brw_custom_taxonomy_dropdown').val("").trigger("change");
            wrap_search.find('input[name="ovabrw_pickup_date"]').val('').trigger("change");
            wrap_search.find('input[name="ovabrw_pickoff_date"]').val('').trigger("change");

            if ( typeof guests === "undefined" || ! guests ) {
                guests = 0;
            }
            wrap_search.find('input[name="ovabrw_guests"]').val(guests);


            // reset sort by
            wrap_search.find('.input_select_list .term_item ').removeClass('term_item_selected');
            wrap_search.find('.input_select_list .term_item[data-id="'+sort_by_default+'"]').addClass('term_item_selected');

            var input_select_text = wrap_search.find('.input_select_list .term_item[data-id="'+sort_by_default+'"]').data('value');
            wrap_search.find('.input_select_input').val(input_select_text);
            wrap_search.find('.input_select_input_value').val(sort_by_default);

            var search_result = wrap_search.find('.brw-search-ajax-result');
            if ( sort_by_default == 'id_desc' ) {
                search_result.data('order','DESC');
                search_result.data('orderby','ID');
                search_result.data('orderby_meta_key','');
            } else if( sort_by_default == 'rating_desc' ) {
                search_result.data('order','DESC');
                search_result.data('orderby','meta_value_num');
                search_result.data('orderby_meta_key','_wc_average_rating');
            }  
            else if( sort_by_default == 'price_asc' ) {
                search_result.data('order','ASC');
                search_result.data('orderby','meta_value_num');
                search_result.data('orderby_meta_key','_price');
            } else if( sort_by_default == 'price_desc' ) {
                search_result.data('order','DESC');
                search_result.data('orderby','meta_value_num');
                search_result.data('orderby_meta_key','_price');
            }      

            loadAjaxSearch();
        });

        /* load ajax search tour */
        function loadAjaxSearch( clicked = null ) {
            var that            = $(document).find('.ovabrw-search-ajax .wrap-search-ajax');
            var layout          = that.find('.filter-layout-active').attr('data-layout');
            var grid_column     = that.data('grid_column');

            var custom_taxonomy = [];
            var taxonomy_value  = [];

            that.find(".brw_custom_taxonomy_dropdown").each(function (index) {
                var nameTaxonomy    = $(this).attr('name');
                var valueTaxonomy   = $(this).val();
                custom_taxonomy[index]  = nameTaxonomy; 
                taxonomy_value[index]   = valueTaxonomy;
            });

            var start_date       = that.find('input[name="ovabrw_pickup_date"]').val();
            var end_date         = that.find('input[name="ovabrw_pickoff_date"]').val();
            var guests           = that.find('input[name="ovabrw_guests"]').val();
            var result           = that.find('.brw-search-ajax-result');
            var order            = result.data('order');
            var orderby          = result.data('orderby');
            var orderby_meta_key = result.data('orderby_meta_key');
            var posts_per_page   = result.data('posts-per-page');
            var paged            = result.find('.ovabrw-pagination-ajax .current').attr('data-paged');

            that.find('.wrap-load-more').show();

            var data_ajax = {
                action: 'ovabrw_search_ajax',
                order: order,
                orderby: orderby,
                orderby_meta_key: orderby_meta_key,
                posts_per_page: posts_per_page,
                paged: paged,
                layout: layout,
                grid_column: grid_column,
                custom_taxonomy: custom_taxonomy,
                taxonomy_value: taxonomy_value,
                start_date: start_date,
                end_date: end_date,
                guests: guests,
                clicked: clicked,
            };

            $.ajax({
                url: ajax_object.ajax_url,
                type: 'POST',
                data: data_ajax,
                success:function(response) {
                    if( response ){
                        var json = JSON.parse( response );
                        var item = $(json.result).fadeOut(300).fadeIn(500);
                        result.html(item);

                        // update number results found
                        var number_results_found =  result.find('.tour_number_results_found').val();

                        if ( number_results_found == undefined ) {
                            number_results_found = 0 ;
                        };

                        result.closest('.wrap-search-ajax').find('.number-result-tour-found').html('').append( number_results_found  );
                        
                        // hide icon loading ajax
                        that.find('.wrap-load-more').hide();
                    }
                },
            });
        }
    },

    ova_period_time: function() {
        var current_time = '';

        $( 'body' ).on( 'change', '.start_date_perido_time', function() {
            var that = $(this);
            
            
            var start_date = that.val();
            var product_id = that.closest('form').find('input[name="product_id"]').val();

            if ( start_date && start_date != current_time ) {
                current_time = start_date;
                
                that.closest('form').find( 'select[name="ovabrw_period_package_id"]' ).attr( 'disabled', 'disabled' );

                $.ajax({
                    url: ajax_object.ajax_url,
                    type: 'POST',
                    data: ({
                        action: 'ovabrw_get_package_by_time',
                        startdate: start_date,
                        post_id: product_id
                    }),
                    success: function(response) {

                        that.closest('form').find( 'select[name="ovabrw_period_package_id"] option ' ).remove();

                        var option = '';
                        var option_obj = $.parseJSON( response );

                        if ( option_obj ) {
                            $.each(option_obj, function(key, value) {
                                option += '<option value="'+ key + '">' + value + '</option>';  
                            });
                        }

                        if ( option ) {
                            that.closest('form').find( 'select[name="ovabrw_period_package_id"]' ).append(option);
                        }

                        if ( that.closest('form').find( 'select[name="ovabrw_period_package_id"]' ).hasClass("select2-hidden-accessible") ) {
                            that.closest('form').find( 'select[name="ovabrw_period_package_id"]' ).select2();
                        }

                        that.closest('form').find( 'select[name="ovabrw_period_package_id"] ' ).prop("disabled", false);
                        that.closest('form').find( '.show_ajax_content' ).removeClass('active');
                        that.closest('form').find( '.show_total' ).html('');
                    },
                });
            }
        });
    },
};

/* ready */
$(document).ready(function () {
    Brw_Frontend.init();
});

})(jQuery);